<template>
  <div v-if="store">
    <nuxt-img
      v-if="store.imageUrl"
      class="block mb-12 sm:mb-16 w-full object-cover aspect-square rounded-md"
      :src="getImagePrefix(store.imageUrl)"
      :loading="index != 0 ? 'lazy' : 'eager'"
      preset="standard"
      sizes="100px sm:320px md:420px"
      :alt="store.imageAltText ? store.imageAltText : store.pageTitle"
    />
    <h3 v-if="!small && store.city" class="mb-2 text-heading-large md:text-heading-xl md:text-heading-xl">{{ store.city }}</h3>
    <h4 v-if="small && store.city" class="mb-2 text-xsmall-medium text-center md:text-left sm:text-small-medium sm:mb-6">{{ store.city }}</h4>
    <div
      v-if="store.address"
      class="text-small-regular"
      :class="{
        'hidden md:block': small
      }"
    >
      {{ store.address }}
    </div>
  </div>
</template>

<script setup lang="ts">
import useImages from '~/composables/useImages';
import type { IStoreItem } from '~/api-types';

const { getImagePrefix } = useImages();

defineProps<{
  small: boolean,
  store: IStoreItem,
  index: number
}>();
</script>
